body {
  display: block;
  background: #292d33;
  background-image: url("/public/red-flower-high.jpg");
  background-repeat: repeat-y;
  background-size: 200%;
}
.whipe {
  clip-path: circle(0px at center);
  animation: whipeOut 1s ease-in 0.3s forwards;
}

.blink {
  display: inline-block;
  content: '';
  width: 3px;
  height: 50px;
  position: relative;
  right: 3px;
  top: 2px;
  border-radius: 5px;
  background-color: #ffffff;
  animation: cursor 1s none 1s infinite;
}

@keyframes whipeOut {
  0% { clip-path: circle(0px at center) }
  100% { clip-path: circle(500px at center) }
}

@keyframes glide {
  0% { opacity: 0; left: -26pt }
  100% { opacity: 1; left: -16pt }
}

@keyframes cursor {
  0% { opacity: 1 }
  50% { opacity: 1 }
  51% { opacity: 0 }
  100% { opacity: 0 }
}

@media screen and (max-width: 1200px) {
  body {
    background-size: auto;
  }
}